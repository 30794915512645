export const INVISIBILITY_REASONS = {
  ORDERED: 'Ordered',
  CLEANING: 'Cleaning',
  REPAIR: 'Repair',
  DAMAGED: 'Damaged',
  MISSING: 'Missing',
  REMOVED_BY_AP: 'Removed by AP',
  REMOVED_BY_ADMIN: 'Removed by Admin',
  TEMP_REMOVED_BY_ADMIN: 'Temporarily Removed by Admin',
  AWAITING_DELIVERY: 'Awaiting Delivery',
  TO_BE_SOLD: 'To Be Sold',
  TO_BE_DISCARDED: 'To Be Discarded',
  DELIVERY: '', // Fix for old instances have old DELIVERY value applied (displays NO REASON in table)
};

export const TRANSPORTATION_TYPE = {
  DELIVERY: 'DELIVERY',
  PICKUP: 'PICKUP',
};

export const ORDER_STATUS = {
  CANCELLED: {
    value: 'CANCELLED',
    label: 'Cancelled',
  },
  CREATED: {
    value: 'CREATED',
    label: 'Created',
  },
  IN_TRANSIT: {
    value: 'IN_TRANSIT',
    label: 'In transit',
  },
  PROCESSING: {
    value: 'PROCESSING',
    label: 'Processing',
  },
  COMPLETED: {
    value: 'COMPLETED',
    label: 'Completed',
  },
};

export const TRANSPORTING_STATUS_OPTIONS = {
  CANCELLED: {
    value: 'CANCELLED',
    label: 'Cancelled',
    color: '#ECECEC',
  },
  CREATED: {
    value: 'CREATED',
    label: 'Created',
    color: '#ECECEC',
  },
  PROCESSING: {
    value: 'PROCESSING',
    label: 'Processing',
    color: '#ECECEC',
  },
  READY: {
    value: 'READY',
    label: 'Ready for transport',
    color: '#CDEFCD',
  },
  IN_TRANSIT: {
    value: 'IN_TRANSIT',
    label: 'In transit',
    color: '#CDEFCD',
  },
  COMPLETED: {
    value: 'COMPLETED',
    label: 'Completed',
    color: '#85CB85',
  },
  // Partial and Failed values handling only on frontend side
  PARTIAL: {
    value: 'PARTIAL',
    label: 'Partial',
    color: '#E6CF5F',
  },
  FAILED: {
    value: 'FAILED',
    label: 'Failed to deliver',
    color: '#DA4453',
  },
};
export const SHIPPING_PROVIDER_OPTIONS = [
  'PostNord',
  'DHL',
  'UPS',
  'Bring',
  'FedEx',
  'Other option',
];

export const FILE_TEMPLATE_OF_BATCH_ORDER_URL =
  'https://storage.googleapis.com/beleco_excel_files/Ordermall.xlsx';

// TODO I think this constant duplicate functionality from TRANSPORTING_STATUS_OPTIONS
export const STATUS_COLOR_MAP = {
  NEW: '#ECECEC',
  CREATED: '#ECECEC',
  RECEIVED: '#85CB85',
  COMPLETED: '#85CB85',
  READY: '#CDEFCD',
  CANCELED: '#ECECEC',
  CANCELLED: 'white',
  PARTIAL: '#E6CF5F',
  PROCESSING: '#ECECEC',
  IN_TRANSIT: '#CDEFCD',
  'N/A': '#ECECEC',
};

export const GOOGLE_MAPS_URI =
  'https://www.google.com/maps/search/?api=1&query=';

export const DIRECTION_FILTER_OPTIONS = [
  {
    value: '',
    name: 'All directions',
  },
  {
    value: 'DELIVERY',
    name: 'Delivery',
  },
  {
    value: 'PICKUP',
    name: 'Pickup',
  },
];
export const FAILURE_FILTER_OPTIONS = [
  {
    value: null,
    name: 'All deliveries',
  },
  {
    value: true,
    name: 'Only failed deliveries',
  },
  {
    value: false,
    name: 'Hide failed deliveries',
  },
];

export const TRANSPORTS_TOOLTIPS = {
  EDIT: 'Swap instance id with another id',
  MOUNTING: 'Instance needs to be mounted',
  NOT_IN_STORAGE: 'Instance is currently not in storage.',
  NEW: 'First time instance is transported',
  NEEDS_ATTENTION:
    'Some of the content of this transport will require extra attention',
  PHONE_BOOTH: 'This transport contains a phone booth',
};

export const WAREHOUSE_STATUS = {
  CREATED: { value: 'CREATED', label: 'Created' },
  AWAY: { value: 'AWAY', label: 'Away' },
  IN_STORAGE: { value: 'IN_STORAGE', label: 'In storage' },
  REMOVED: { value: 'REMOVED', label: 'Removed' },
  MISSING: { value: 'MISSING', label: 'Missing' },
};

export const HEADER_HEIGHT = 65;

export const LOCALES = {
  en: {
    localeCode: 'en-GB',
  },
  da: {
    localeCode: 'da-DK',
  },
  de: {
    localeCode: 'de-DE',
  },
  fi: {
    localeCode: 'fi-FI',
  },
  no: {
    localeCode: 'nb-NO',
  },
  sv: {
    localeCode: 'sv-SE',
  },
};
