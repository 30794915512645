import API from './API';

class InventoryAPI extends API {
  getInstances({
    limit = 10,
    offset = 0,
    search = '',
    locationId = [],
    invisibilityReason = [],
    warehouseStatus = [],
  }) {
    const params = {
      limit,
      offset,
      search,
      locationId,
      invisibilityReason,
      warehouseStatus,
    };
    return this.api.get('/instances', { params });
  }

  getInstanceDetails({ publicId = '' }) {
    const path = `/instances/${publicId}`; // lower/uppercase issues probabl should be fixed on BE
    return this.api.get(path);
  }

  getInstanceTransports({ publicId = '', limit = 10, offset = 0 }) {
    const params = { limit, offset };
    const path = `/instances/${publicId}/transports`;
    return this.api.get(path, { params });
  }

  getInstanceBookings({ publicId = '', limit = 10, offset = 0 }) {
    const params = { limit, offset };
    const path = `/instances/${publicId}/bookings`;
    return this.api.get(path, { params });
  }

  getDoubleBookedInstances(params) {
    return this.api.get('/bookings/double-bookings', { params });
  }

  getInstancesAvailability(params) {
    return this.api.get('/instances/availability', { params });
  }

  getInstanceHistory(params) {
    return this.api.get('/instances/history', { params });
  }

  getProductsAvailability(params) {
    return this.api.get('/products/availability', { params });
  }

  getDoubleBookingStatus(idsArray) {
    const path = '/bookings/double-booking-status';
    const params = { id: idsArray };
    return this.api.get(path, { params });
  }

  updateInstance({ publicId, data }) {
    const path = `/instances/${publicId}`;
    return this.api.post(path, data);
  }

  getLocations(params) {
    const path = '/locations';
    return this.api.get(path, { params });
  }

  getProductInstances(productId, params) {
    const path = `/products/${productId}/instances`;
    return this.api.get(path, { params });
  }

  getProductData = (productId) => {
    const path = `/products/${productId}`;
    return this.api.get(path);
  };

  updateProductData = ({ productId, params }) => {
    const path = `/products/${productId}`;
    return this.api.post(path, params);
  };

  getAvailabilityPeriods(productId, startDate, endDate, locationId) {
    const params = {
      start: startDate,
      end: endDate,
      locationId,
    };
    const path = `/products/${productId}/period-availability`;
    return this.api.get(path, { params, cache: { exclude: { query: false } } });
  }

  getLocationsAvailability(productId) {
    const path = `/products/${productId}/location-availability`;
    return this.api.get(path);
  }

  createNewInstance({
    productId,
    ownerId,
    isVisible,
    quantity,
    locationId,
    invisibilityReason,
  }) {
    const path = `/instances`;
    const params = {
      productId,
      ownerId,
      isVisible,
      quantity,
      locationId,
      invisibilityReason,
    };
    return this.api.post(path, params);
  }

  refreshQRCode = (publicId) => {
    const path = `/instances/${publicId}/refresh-qr-code`;

    return this.api.post(path);
  };

  getWarehouseOrders = (params) => {
    const path = `/warehouseorders`;
    return this.api.get(path, { params });
  };

  getWarehouseDeliveryDetails = (publicId) => {
    const path = `/warehouseorders/${publicId}`;
    return this.api.get(path);
  };

  getWarehouseDeliveryLabel = (publicId) => {
    const path = `/warehouseorders/${publicId}/label`;
    return this.api.get(path, {
      responseType: 'blob',
    });
  };

  createWarehouseDeliveryLabel = (publicId) => {
    const path = `/warehouseorders/${publicId}/refresh-qr-code`;
    return this.api.post(path);
  };

  createWarehouseDeliveryOrder = (params) => {
    const path = `warehouseorders`;
    return this.api.post(path, params);
  };

  editWarehouseDeliveryOrder = (publicId, params) => {
    const path = `warehouseorders/${publicId}`;
    return this.api.post(path, params);
  };

  cancelWarehouseDeliveryOrder = ({ publicId }) => {
    const path = `warehouseorders/${publicId}/cancel`;
    return this.api.post(path);
  };

  getWarehouseOrderHistory = ({ id }) => {
    const path = '/warehouseorders/history';
    const params = { id };

    return this.api.get(path, { params });
  };

  getAllDamageReports = (params) => {
    return this.api.get(`/damagereports`, { params });
  };

  getInstanceDamageReports = ({ publicId }) => {
    return this.api.get(`/instances/${publicId}/damage-reports`);
  };

  getConditionChangelog = (params) => {
    return this.api.get(`/instances/condition-changelog`, { params });
  };

  createDamageReport = ({ params }) => {
    return this.api.post(`/damagereports`, params);
  };

  updateDamageReport = ({ id, data }) => {
    return this.api.post(`/damagereports/${id}`, data);
  };

  deleteDamageReport = ({ id }) => {
    return this.api.delete(`/damagereports/${id}`);
  };

  createDamageReportImage = ({ id, data }) => {
    return this.api.post(`/damagereports/${id}/image`, data);
  };

  deleteDamageReportImage = ({ id, data }) => {
    return this.api.delete(`/damagereports/${id}/image`, {
      data,
    });
  };

  getInstanceLabels = ({ ids }) => {
    const params = { id: ids };
    return this.api.get('/instances/labels', {
      params,
      responseType: 'blob',
    });
  };

  getTransports = ({
    limit,
    offset,
    from,
    to,
    orderId = '',
    instance = '',
    locationId,
    sort,
    orderBy = 'from',
    isNoShow = null,
    type = '',
    tmsId,
    status,
    isFullyDelivered,
  }) => {
    const params = {
      limit,
      offset,
      from,
      to,
      orderId,
      instance,
      locationId,
      sort,
      orderBy,
      isNoShow,
      type,
      tmsId,
      status,
      isFullyDelivered,
    };
    return this.api.get('/transports', {
      params,
    });
  };

  updateTransport = (params, publicId) => {
    const path = `/transports/${publicId}`;
    return this.api.post(path, params);
  };

  getProducts = ({ limit = 10, offset = 0 }) => {
    const params = { limit, offset };
    const path = `/products/`;
    return this.api.get(path, { params });
  };

  cancelTransport = (publicId) => {
    const path = `/transports/${publicId}/cancel`;
    return this.api.post(path, { publicId });
  };

  getTransportDeliveryInstanceSiblings = ({
    publicId,
    delivery,
    pickup = '2099-01-01',
  }) => {
    const path = `/instances/${publicId}/available-siblings`;
    const params = { delivery, pickup };

    return this.api.get(path, { params });
  };

  getTransportPickupInstanceSiblings = ({ orderId, productId }) => {
    const path = `/orders/${orderId}/instances/available-for-pickup`;
    const params = { productId };

    return this.api.get(path, { params });
  };

  getTransportDetails = (publicId) => {
    const path = `/transports/${publicId}`;

    return this.api.get(path);
  };

  replaceInstanceInTransport = ({
    publicId,
    instanceToReplace,
    newInstance,
  }) => {
    const path = `/transports/${publicId}/instances/${instanceToReplace}`;
    const data = { newInstance };

    return this.api.post(path, data);
  };

  updateTransportPackedProduct = ({ publicId, productId, isPacked }) => {
    const path = `/transports/${publicId}/products/${productId}/is-packed`;

    return this.api.post(path, { isPacked });
  };

  updateTransportInstanceIsPacked = ({ publicId, instanceId, isPacked }) => {
    const path = `/transports/${publicId}/instances/${instanceId}/is-packed`;

    return this.api.post(path, { isPacked });
  };

  updateTransportInstanceIsCompleted = ({
    publicId,
    instanceId,
    isCompleted,
  }) => {
    const path = `/transports/${publicId}/instances/${instanceId}/is-completed`;

    return this.api.post(path, { isCompleted });
  };

  updateWarehouseOrderInstanceIsCompleted = ({
    publicId,
    instanceId,
    isCompleted,
  }) => {
    const path = `/warehouseorders/${publicId}/instances/${instanceId}/is-completed`;

    return this.api.post(path, { isCompleted });
  };

  updateWarehouseOrderProductIsCompleted = ({
    publicId,
    productId,
    isCompleted,
  }) => {
    const path = `/warehouseorders/${publicId}/products/${productId}/is-completed`;

    return this.api.post(path, { isCompleted });
  };

  getTransportHistory = ({ id }) => {
    const path = '/transports/history';
    const params = { id };

    return this.api.get(path, { params });
  };

  getTMSList = () => {
    const path = '/tms';

    return this.api.get(path);
  };
}

export default InventoryAPI;
