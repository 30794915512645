import { createTheme } from '@mui/material/styles';

import OppositMediumWoff from './assets/fonts/gtf-opposit/Opposit-Medium.woff';
import SequelWoff from './assets/fonts/sequel/Sequel-100-Wide-45.woff';
import GTAmericaThinWoff from './assets/fonts/gt-america/GT-America-Standard-Thin.woff';

const opposit = {
  fontFamily: 'Opposit-Medium',
  src: `
    url(${OppositMediumWoff}) format('woff')
  `,
};

const sequel = {
  fontFamily: 'Sequel',
  src: `
    url(${SequelWoff}) format('woff')
  `,
};

const gtAmerica = {
  fontFamily: 'GT-America-Standard-Thin',
  src: `
    url(${GTAmericaThinWoff}) format('woff')
  `,
};

const mainTheme = createTheme({
  palette: {
    primary: {
      main: '#292929',
      dark: '#000000',
      light: '#fff',
    },
    secondary: {
      main: '#f8f8f8',
      light: '#e9e9e9',
      dark: '#d4d4d4',
    },
    background: {
      default: '#fff',
    },
    title: {
      secondary: '#8F8F8F',
      highlighted: '#DA4453',
    },
  },
  typography: {
    useNextVariants: true,
    h1: {
      fontFamily: 'Opposit-Medium, sans-serif',
      fontSize: 35,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.5px',
      color: '#000',
    },
    h2: {
      fontFamily: 'Opposit-Medium, sans-serif',
      fontSize: 25,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.5px',
      color: '#000',
    },
    h3: {
      fontFamily: 'Sequel, sans-serif',
      fontSize: 16,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.4px',
      color: '#2a2929',
    },
    h4: {
      fontFamily: 'Sequel, sans-serif',
      fontSize: 20,
      fontWeight: 500,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.3px',
      color: '#000',
    },
    h5: {
      fontFamily: 'Sequel, sans-serif',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 14,
      color: '#000000',
    },
    h6: {
      fontFamily: 'Sequel, sans-serif',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 12,
      color: '#8B8B8B',
    },
    subtitle1: {
      fontFamily: 'Sequel, sans-serif',
      fontSize: 9,
      fontStyle: 'normal',
      color: '#555555',
      textTransform: 'uppercase',
      fontWeight: 'normal',
      lineHeight: '2.2',
      letterSpacing: '0.2px',
    },
    body1: {
      fontFamily: 'GT-America-Standard-Thin, sans-serif',
      fontWeight: 'normal',
      fontSize: 14,
      fontStyle: 'normal',
      fontStretch: 'normal',
      lineHeight: '1',
      letterSpacing: 'normal',
      color: '#2a2929',
    },
    body2: {
      fontSize: 12,
      fontFamily: 'Helvetica Neue, sans-serif',
    },
    captionText: {
      fontFamily: 'GT-America-Standard-Thin, sans-serif',
      fontWeight: 500,
      fontStyle: 'normal',
      fontSize: 12,
      color: '#292929',
      opacity: 0.8,
    },
    subtitle2: {
      fontFamily: 'Helvetica Neue, sans-serif',
      fontSize: 12,
      color: '#DA4453',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        // Issue with multiple @font-face values https://github.com/mui/material-ui/issues/24966
        '@font-face': gtAmerica,

        body: {
          '@font-face': sequel,
          fontFamily: 'Helvetica Neue, sans-serif',
        },
        html: {
          '@font-face': opposit,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: 'Helvetica Neue, sans-serif',
          fontSize: 12,
          fontStyle: 'normal',
          textTransform: 'uppercase',
          fontWeight: 'normal',
          lineHeight: '2.2',
          letterSpacing: '0.2px',
        },
        contained: {
          backgroundColor: '#000',
          color: '#fff',
          boxShadow: 'none',
          padding: '4px 20px 4px',
          borderRadius: '25px',
          whiteSpace: 'nowrap',
          '&:hover': {
            color: '#9c9c9c',
            backgroundColor: '#000',
          },
        },
        outlined: {
          color: '#000',
          border: '1px solid #000',
          padding: '8px 30px 8px',
          borderRadius: '25px',
          '&:hover': {
            backgroundColor: '#000',
            color: '#fff',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          paddingRight: 0,
          fontSize: 12,
          fontStyle: 'normal',
          fontFamily: 'Helvetica Neue, sans-serif',
          color: 'black',
          fontWeight: 'normal',
          lineHeight: '20px',
          letterSpacing: '0.2px',
          display: 'flex',
          alignItems: 'center',

          '& .MuiTypography-root': {
            fontSize: 12,
            lineHeight: '20px',
            fontStyle: 'normal',
            fontFamily: 'Helvetica Neue, sans-serif',
            color: 'black',
            fontWeight: 'normal',
            letterSpacing: '0.2px',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          },
        },
        selectMenu: {
          padding: '6px 10px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          height: 20,
          padding: '8.5px 14px',
          fontFamily: 'Helvetica Neue, sans-serif',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlined: {
          fontFamily: 'Helvetica Neue, sans-serif',
        },
      },
    },
  },
});

export default mainTheme;
