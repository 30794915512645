import { useContext } from 'react';
import { Grid } from '@mui/material';
import { useParams } from 'react-router';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import InstanceView from './InstanceView';
import BookingList from './components/Bookings';
import OwnerInfo from '../../components/OwnerInfo';
import StorageInfo from './components/StorageInfo';
import QRCode from './components/QRCode';
import Changelog from '../Changelog/Changelog';
import belecoApi from '../../api';
import CHANGELOG_TYPES from '../Changelog/changelogTypes';
import decode from '../../utils/htmlDecode';
import DamageReports from './components/DamageReports';
import InstanceDetailsProvider, {
  InstanceDetailsContext,
} from '../../context/InstanceDetails/InstanceDetailsContext';
import ChangelogProvider from '../../context/Changelog/ChangelogContext';
import TransportsList from './components/Transports';

const InstanceDetailsContent = () => {
  const { t } = useTranslation();
  const { publicId } = useParams();

  const {
    instanceData,
    transports,
    isTransportsLoading,
    bookings,
    isBookingsLoading,
    owner,
    isOwnerLoading,
    handleChangeBookingsPage,
    handleChangeTransportsPage,
  } = useContext(InstanceDetailsContext);

  return (
    <>
      <Helmet>
        <title>{`${t('Instance')} ${
          instanceData.publicId || decode(instanceData.title)
        }`}</title>
      </Helmet>
      <Grid container directtion='row' alignItems='stretch' spacing={2}>
        <Grid item md={8} xs={12}>
          <InstanceView />
          <BookingList
            data={bookings}
            loading={isBookingsLoading}
            onChangePage={handleChangeBookingsPage}
          />
          <TransportsList
            data={transports}
            onChangePage={handleChangeTransportsPage}
            isLoading={isTransportsLoading}
          />
          <DamageReports publicId={publicId} instanceData={instanceData} />
        </Grid>
        <Grid item md={4} xs={12}>
          <StorageInfo />
          <Changelog
            changelogType={CHANGELOG_TYPES.INSTANCE}
            getData={() => belecoApi.inv.getInstanceHistory({ id: publicId })}
          />
          <QRCode />
          <OwnerInfo loading={isOwnerLoading} data={owner} />
        </Grid>
      </Grid>
    </>
  );
};

const InstanceDetails = () => {
  return (
    <ChangelogProvider>
      <InstanceDetailsProvider>
        <InstanceDetailsContent />
      </InstanceDetailsProvider>
    </ChangelogProvider>
  );
};

export default InstanceDetails;
