import { useMemo, useState, createContext, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import belecoApi from '../../api';
import useNotificator from '../../utils/useNotificator';
import downloadImageFromBlob from '../../utils/downloadImageFromBlob';
import { ChangelogContext } from '../Changelog/ChangelogContext';
import LocationContext from '../Location/LocationContext';

export const InstanceDetailsContext = createContext({});

const InstanceDetailsProvider = ({ children }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { notifyError, notifySuccess } = useNotificator();
  const { publicId } = useParams();

  const [instanceData, setInstanceData] = useState({});
  const [isInstanceDataLoading, setIsInstanceDataLoading] = useState(false);

  const [bookings, setBookings] = useState({});
  const [isBookingsLoading, setIsBookingsLoading] = useState(false);

  const [transports, setTransports] = useState({});
  const [isTransportsLoading, setIsTransportsLoading] = useState(false);

  const [owner, setOwner] = useState({});
  const [isOwnerLoading, setIsOwnerLoading] = useState(false);
  const { getChangelog } = useContext(ChangelogContext);
  const { locations } = useContext(LocationContext);

  const loadInstanceData = async () => {
    setIsInstanceDataLoading(true);
    try {
      const data = await belecoApi.inv.getInstanceDetails({ publicId });
      const meta = await belecoApi.wp.getProductsMeta([data.productId]);
      data.title = meta[data.productId].title;
      data.image = meta[data.productId].image;
      setInstanceData(data);
    } catch (err) {
      notifyError(err, 'Failed to load instance details');
      push('/instances');
    } finally {
      setIsInstanceDataLoading(false);
    }
  };

  const loadBookingsForInstance = async ({ offset = 0, limit = 50 }) => {
    setIsBookingsLoading(true);
    try {
      const data = await belecoApi.inv.getInstanceBookings({
        publicId,
        offset,
        limit,
      });

      const bookingIds = data.rows.map((booking) => booking.id);
      const doubleBookingStatusData = bookingIds.length
        ? await belecoApi.inv.getDoubleBookingStatus(bookingIds)
        : [];
      const doubleBookingMap = doubleBookingStatusData.reduce((prev, curr) => {
        return { ...prev, [curr.id]: curr.isDoubleBooking };
      }, {});
      const rows = data.rows.map((row) => {
        return {
          ...row,
          isAvailable: !doubleBookingMap[row.id],
          isDoubleBooked: doubleBookingMap[row.id],
        };
      });
      const { count } = data;
      setBookings({ rows, count, page: offset / limit + 1 });
    } catch (err) {
      notifyError(err, 'Unable to load bookings for instance');
    } finally {
      setIsBookingsLoading(false);
    }
  };

  const loadOwner = async ({ ownerId }) => {
    setIsOwnerLoading(true);
    try {
      const data = await belecoApi.wp.getOwnerData(ownerId);
      setOwner(data);
    } catch (err) {
      notifyError(err, 'Unknown owner');
    } finally {
      setIsOwnerLoading(false);
    }
  };

  const updateInstance = async ({ data }) => {
    setIsInstanceDataLoading(true);

    try {
      await belecoApi.inv.updateInstance({ publicId, data });
      const newInstanceData = { ...instanceData, ...data };

      setInstanceData(newInstanceData);
      notifySuccess(t('Instance updated!'));
      getChangelog(() => belecoApi.inv.getInstanceHistory({ id: publicId }));
    } catch (err) {
      notifyError(err, t('Failed to update the Instance'));
    } finally {
      setIsInstanceDataLoading(false);
    }
  };
  const loadTransportsForInstance = async ({ offset = 0, limit = 50 }) => {
    setIsTransportsLoading(true);

    try {
      const rows = await belecoApi.inv.getInstanceTransports({
        publicId,
        offset,
        limit,
      });

      rows.map((row) => {
        row.location = locations[instanceData.locationId];
        return row;
      });
      const count = rows.length;
      setTransports({ rows, count, page: offset / limit + 1 });
    } catch (err) {
      notifyError(err, 'Unable to load transports for instance');
    } finally {
      setIsTransportsLoading(false);
    }
  };
  const refreshQRCode = async (id) => {
    setIsInstanceDataLoading(true);
    try {
      const data = await belecoApi.inv.refreshQRCode(id);
      setInstanceData({ ...instanceData, ...data });
    } catch (err) {
      notifyError(err, 'Refresh qr code failed');
    } finally {
      setIsInstanceDataLoading(false);
    }
  };

  const getQRCodeLabelPdf = async ({ ids, filename }) => {
    try {
      belecoApi.inv
        .getInstanceLabels({ ids })
        .then((blob) => downloadImageFromBlob(blob, filename));
    } catch (err) {
      notifyError(err, 'Get qr code label failed');
    }
  };
  const handleChangeTransportsPage = (pageIndex) => {
    const offset = pageIndex * 50;
    loadTransportsForInstance({ limit: 50, offset });
  };
  const handleChangeBookingsPage = (pageIndex) => {
    const offset = pageIndex * 50;
    loadBookingsForInstance({ limit: 50, offset });
  };
  useEffect(() => {
    loadInstanceData();
    loadBookingsForInstance({});
  }, []);
  useEffect(() => {
    if (instanceData.locationId) {
      loadTransportsForInstance({});
    }
  }, [instanceData.locationId]);
  useEffect(() => {
    if (instanceData.ownerId) {
      loadOwner({ ownerId: instanceData.ownerId });
    }
  }, [instanceData.ownerId]);

  const returnValues = useMemo(
    () => ({
      instanceData,
      isInstanceDataLoading,
      isTransportsLoading,
      transports,
      bookings,
      isBookingsLoading,
      owner,
      isOwnerLoading,
      handleChangeTransportsPage,
      updateInstance,
      handleChangeBookingsPage,
      refreshQRCode,
      getQRCodeLabelPdf,
    }),
    [
      instanceData,
      isInstanceDataLoading,
      transports,
      isTransportsLoading,
      bookings,
      isBookingsLoading,
      owner,
      isOwnerLoading,
    ],
  );

  return (
    <InstanceDetailsContext.Provider value={returnValues}>
      {children}
    </InstanceDetailsContext.Provider>
  );
};

InstanceDetailsProvider.propTypes = {
  children: PropTypes.shape({}).isRequired,
};

export default InstanceDetailsProvider;
