import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Typography, Link, Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTranslation } from 'react-i18next';
import CustomDataGrid from '../../../../components/CustomDataGrid';
import BookingStatusComponent from './components/BookingStatus';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
    padding: 20,
    backgroundColor: '#ffffff',
    border: '1px solid #c5c5c5',
    borderRadius: '7px',
    '& .MuiTableCell-root.MuiTableCell-body': {
      padding: '2px 5px',
      maxWidth: 100,
      textAlign: 'center',
    },
    '& .MuiTableBody-root tr.MuiTableRow-root:not([index])': {
      display: 'none',
    },
  },
  tableName: {
    fontSize: '34px',
    fontStyle: 'normal',
    fontStretch: 'normal',
    textTransform: 'uppercase',
    lineHeight: 1,
  },
  dataGridClass: {
    '& .MuiDataGrid-row': {
      boxShadow: 'inset 0 0 0 1px #cccccc',
      width: '100%',

      '& .MuiDataGrid-cell': {
        border: 'none',
        fontWeight: 600,
        fontFamily: 'Helvetica Neue, sans-serif',

        '& p': {
          fontFamily: 'inherit',
        },
      },
    },
  },
});

const wpAdminURL = process.env.REACT_APP_WP_ADMIN_URL;

const BookingList = ({ data, loading, onChangePage }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const initColumns = useMemo(
    () => [
      {
        headerName: t('Order id'),
        field: 'orderId',
        align: 'left',
        headerAlign: 'left',
        sortable: false,
        flex: 1,
        minWidth: 80,
        renderCell: ({ row }) => (
          <Link href={`${wpAdminURL}/post.php?post=${row.orderId}&action=edit`}>
            #{row.orderId}
          </Link>
        ),
      },
      {
        headerName: t('Going out'),
        field: 'deliveryDate',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 1,
        minWidth: 140,
        renderCell: ({ row }) => <Typography>{row.deliveryDate}</Typography>,
      },
      {
        headerName: t('Coming back'),
        field: 'pickupDate',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => <Typography>{row.pickupDate}</Typography>,
      },
      {
        headerName: t('Status'),
        field: 'isAvailable',
        align: 'center',
        headerAlign: 'center',
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <BookingStatusComponent
            isAvailable={row.isAvailable}
            isDoubleBooked={row.isDoubleBooked}
          />
        ),
      },
    ],
    [i18n.language],
  );

  const { rows = [], count } = data;

  return (
    <div className={classes.container}>
      <Box display='flex' flexDirection='row' justifyContent='space-between'>
        <Typography className={classes.tableName}>{t('Bookings')}</Typography>
      </Box>
      <CustomDataGrid
        columns={initColumns}
        rows={rows}
        dataGridClass={classes.dataGridClass}
        rowCount={count || 0}
        pageSize={50}
        onPageChange={onChangePage}
        headerHeight={40}
        rowHeight={40}
        rowSpacing={5}
        pagination
        paginationMode='server'
        hideFooterPagination={false}
        loading={loading}
      />
    </div>
  );
};

BookingList.propTypes = {
  data: PropTypes.object.isRequired,
  // eslint-disable-next-line react/boolean-prop-naming
  loading: PropTypes.bool.isRequired,
  onChangePage: PropTypes.func.isRequired,
};

export default BookingList;
